import { DOMAIN } from '../../../../../config/env';
import './style.scss';
import React, { useEffect } from 'react';
import { RESSOURCE } from '../../../../../config/constants';

const PlanoCard = ({ plano }) => {
  if (!!!plano) return null;

  return (
    <div className="plano-card">
      <header className="plano-card__header">
        <h3 className="plano-card-title">{plano.pga_lib}</h3>
        <ul className="plano-card-info">
          <li>
            &nbsp;
            {plano.ray_lib} - {plano.fam_lib}
          </li>
          <li>
            Chef produits : &nbsp;
            {plano.che_nom}
          </li>
        </ul>
        <section className="plano-card__section">
          {' '}
          {plano && plano.pga_commentaires && plano.pga_commentaires.split('<br>').map((item, index) => (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </section>
      </header>
      <section className="plano-card__section">
        <ul className="plano-card-info">
          <li>
            <ul>
            {plano && (plano.pga_d_commentaire || plano.pga_d_commentaire2) && (
              <li>
                {plano.pga_d_commentaire &&
                  plano.pga_d_commentaire.split('<br>').map((item, index) => (
                    <React.Fragment key={index}>
                      {item}
                      <br />
                    </React.Fragment>
                  ))}
                {plano.pga_d_commentaire2 &&
                  plano.pga_d_commentaire2.split('<br>').map((item, index) => (
                    <React.Fragment key={`comment2-${index}`}>
                      {item}
                      <br />
                    </React.Fragment>
                  ))}
              </li>
            )}
              {plano.pga_d_fd_plein && plano.pga_d_fd_plein.length > 1 && (
                <li>Fond plein : {plano.pga_d_fd_plein}</li>
              )}
              {plano.pga_d_fd_perfo && plano.pga_d_fd_perfo.length > 1 && (
                <li>Fond perfo. : {plano.pga_d_fd_perfo}</li>
              )}
              {plano.pga_d_broche && plano.pga_d_broche.length > 1 && (
                <li>Broches : {plano.pga_d_broche}</li>
              )}
              {plano.pga_d_tablette && plano.pga_d_tablette.length > 1 && (
                <li>Tablettes : {plano.pga_d_tablette}</li>
              )}
              {plano.pga_d_mat_spec && plano.pga_d_mat_spec.length > 1 && (
                <li>Matériel spécifique : {plano.pga_d_mat_spec}</li>
              )}
            </ul>
          </li>
        </ul>
      </section>
      <section className="plano-card__section">
        {plano.pga_photo && plano.pga_photo.length > 1 && (
          <div className="plano-card__section-photo">
            <a
              href={
                DOMAIN.URL +
                '/' +
                RESSOURCE.PHOTO_PLANOS +
                'P' +
                plano.pga_code +
                '.jpg'
              }
              target="_blank"
              title="ZOOM"
              rel="noreferrer"
            >
              <img
                className="plano-card-photo"
                src={
                  DOMAIN.URL +
                  '/' +
                  RESSOURCE.PHOTO_PLANOS +
                  'mini/P' +
                  plano.pga_code +
                  '.jpg'
                }
                alt="plano"
              />
            </a>
          </div>
        )}
      </section>
    </div>
  );
};

export default PlanoCard;
