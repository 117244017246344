import React from "react";
import "./style.scss";

const RenderCell = ({ row, column }) => {
  // Vérifie si la colonne a une cellule custom
  if (!column.Cell) {
    // Affiche la valeur de la cellule en fonction de la colonne
    return <>{row[column.accessor]}</>;
  }
  // Retourne la cellule custom
  return <>{column.Cell(row)}</>;
};

const TitleRowTable = ({ columns, data, titleRow, dataRow, header = true }) => {
  if (!columns) return null;

  // Trier les groupes par ordre alphabétique en fonction du titre
  const sortedData = [...data].sort((a, b) =>
    a[titleRow.accessor].localeCompare(b[titleRow.accessor])
  );

  return (
    <div className="title-row-table-wrapper">
      <table className="title-row-table">
        <thead>
          <tr className="head-row">
            {header &&
              columns.map((column, index) => (
                <th key={index}>{column.header}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((group, groupIndex) => {
            if (
              !group[dataRow.accessor] ||
              group[dataRow.accessor].length === 0
            )
              return null;
            // Trier les lignes de chaque groupe par ordre alphabétique selon la première colonne
            const sortedRows = group[dataRow.accessor]
              .slice()
              .sort((a, b) =>
                a[columns[0].accessor].localeCompare(b[columns[0].accessor])
              );
            return (
              <React.Fragment key={groupIndex}>
                <tr className="title-row">
                  <td colSpan={columns.length}>{group[titleRow.accessor]}</td>
                </tr>
                {sortedRows.map((row, rowIndex) => (
                  <tr className="row" key={rowIndex}>
                    {columns.map((column, columnIndex) => (
                      <td key={columnIndex}>
                        <RenderCell row={row} column={column} />
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TitleRowTable;
