// src/contexts/OperationContext.js
import React, { createContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getOperationByMagasin,
  saveMagOpe,
  saveMapOption,
  deleteMagOpe,
} from "../../../../services/engagement_apis";
import useAuth from "../../../../hooks/useAuth";
import { PATH } from "../../../../config/constants";

const mapMagOpeToFormState = (magOpe, operationsData, year) => {
  const cxMagOpe =
    magOpe.cx_mag_ope && magOpe.cx_mag_ope.length > 0
      ? magOpe.cx_mag_ope[0]
      : {};
  return {
    id_mag_cmd: cxMagOpe.id_mag_cmd,
    id_operation: operationsData.id_operation,
    mag_code: magOpe.mag_code,
    annee: year,
    id_ope_pagi: cxMagOpe.id_ope_pagi || null,
    id_ope_pagi_option: cxMagOpe.id_ope_pagi_option || null,
    mo_choix_date: String(cxMagOpe.mo_choix_date || "1"),
    mo_datedeb3: cxMagOpe.mo_datedeb3 || "",
    mo_datefin3: cxMagOpe.mo_datefin3 || "",
    mo_digital: cxMagOpe.mo_digital ? "1" : "0",
    mo_txt_couv_spe: cxMagOpe.mo_txt_couv_spe || "",
    mo_qte_caisse: cxMagOpe.mo_qte_caisse || 50,
    mo_lien_personnalisable: cxMagOpe.mo_lien_personnalisable ? "1" : "0",
    id_mag_zone: cxMagOpe.id_mag_zone || "0",
    mo_dist_qte: cxMagOpe.mo_dist_qte || "0",
    mo_distribution: String(cxMagOpe.mo_distribution || 0),
    mo_adr_livraison: cxMagOpe.mo_adr_livraison || "",
    mo_courrier_personnalisation: cxMagOpe.mo_courrier_personnalisation || "0",
    mo_courrier_personnalisation_txt:
      cxMagOpe.mo_courrier_personnalisation_txt || "",
    mo_courrier_qte: cxMagOpe.mo_courrier_qte || "0",
    mo_sms_type: cxMagOpe.mo_sms_type || "0",
    mo_sms_qte: cxMagOpe.mo_sms_qte || magOpe.potentiel?.mp_qte_sms || "0",
    mo_loc_sms_type: cxMagOpe.mo_loc_sms_type || "0",
    mo_loc_sms_qte: cxMagOpe.mo_loc_sms_qte || "0",
    mo_bonial: cxMagOpe.mo_bonial ? "1" : "0",
    mo_bonial_qte: cxMagOpe.mo_bonial_qte || "0",
  };
};

export const OperationContext = createContext();

const OperationProvider = ({ children }) => {
  const { magasin } = useAuth();
  const [operationsData, setOperationsData] = useState({});
  const [magOpeData, setMagOpeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState({});
  const [inputPlvValues, setInputPlvValues] = useState({});
  const [budgetData, setBudgetData] = useState(null);
  const [formState, setFormState] = useState({});
  const [resultMagOpe, setResultMagOpe] = useState({});
  const { idOpe, year } = useParams();
  const navigate = useNavigate();
  const [dateErrorMessages, setDateErrorMessages] = useState({
    mo_datedeb3: "",
    mo_datefin3: "",
  });
  const [pagiOptions, setPagiOptions] = useState({});

  useEffect(() => {
    const loadData = async () => {
      try {
        await saveMagOpe({
          id_operation: idOpe,
          mag_code: magasin.mag_code,
          annee: year,
        });
        const operationsResponse = await getOperationByMagasin(
          idOpe,
          magasin.mag_code
        );
        setOperationsData(operationsResponse.operation);
        setMagOpeData(operationsResponse.magOpe);
        const initialFormState = mapMagOpeToFormState(
          operationsResponse.magOpe,
          operationsResponse.operation,
          year
        );
        // Met à jour le formState initial
        setFormState(initialFormState);
        setBudgetData(operationsResponse.magOpe);
        setResultMagOpe(operationsResponse.magOpe);

        const initialInputPlvValues = {};
        operationsResponse.operation.params.forEach((param) => {
          const magOpeOption =
            operationsResponse.magOpe.cx_mag_ope[0]?.mag_ope_option?.find(
              (option) => option.id_operation_param === param.id_operation_param
            );
          initialInputPlvValues[param.id_operation_param] = magOpeOption
            ? magOpeOption.moo_qte
            : param.opt_qte_defaut;
        });

        setInputPlvValues(initialInputPlvValues);

        // Si une pagination est déjà sélectionnée, charger les options associées
        if (initialFormState.id_ope_pagi) {
          const pagiById = operationsResponse.operation.pagi.find(
            (pagi) =>
              pagi.id_ope_pagi === parseInt(initialFormState.id_ope_pagi)
          );
          setPagiOptions(pagiById.pagi_option);

          // Si une option est déjà sélectionnée, l'affecter au formState
          if (initialFormState.id_ope_pagi_option) {
            setFormState((prevState) => ({
              ...prevState,
              id_ope_pagi_option:
                pagiById.pagi_option.find(
                  (option) =>
                    option.id_ope_pagi_option ===
                    initialFormState.id_ope_pagi_option
                )?.id_ope_pagi_option || "",
            }));
          }
        }
      } catch (error) {
        console.error("Could not fetch data from API", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [idOpe, magasin.mag_code, year]);

  useEffect(() => {
    if (formState.mo_distribution === "1") {
      setFormState((prevState) => ({
        ...prevState,
        id_mag_zone: magOpeData.cx_mag_ope[0].id_mag_zone || "0",
      }));
    }
  }, [formState.mo_distribution]);

  useEffect(() => {
    if (formState.mo_distribution === "1") {
      if (formState.mo_dist_qte !== resultMagOpe.mo_dist_qte) {
        setFormState((prevState) => ({
          ...prevState,
          mo_dist_qte: resultMagOpe.mo_dist_qte,
        }));
      }
    }
  }, [resultMagOpe, formState.mo_distribution]);

  useEffect(() => {
    if (
      formState.mo_courrier_personnalisation === "0" &&
      !formState.mo_courrier_personnalisation_txt
    ) {
      setFormState((prevState) => ({
        ...prevState,
        mo_courrier_personnalisation_txt: "",
      }));
    }
  }, [formState.mo_courrier_personnalisation]);

  useEffect(() => {
    const loadDatas = async () => {
      try {
        const magOpeResponse = await saveMagOpe(formState);
        setBudgetData(magOpeResponse.CxMagOpe);
        setResultMagOpe(magOpeResponse.CxMagOpe);
      } catch (error) {
        console.error("Could not fetch data from API", error);
      }
    };
    loadDatas();
  }, [formState]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "id_ope_pagi") {
      const pagiById = operationsData.pagi.find(
        (pagi) => pagi.id_ope_pagi === parseInt(value)
      );

      if (pagiById && pagiById.pagi_option && pagiById.pagi_option.length > 0) {
        setPagiOptions(pagiById.pagi_option);
      } else {
        setPagiOptions([]);
      }

      setFormState((prevState) => ({
        ...prevState,
        id_ope_pagi: value,
        id_ope_pagi_option:
          pagiById && pagiById.pagi_option && pagiById.pagi_option.length > 0
            ? prevState.id_ope_pagi_option
            : null,
      }));
    }
    // Validation des dates
    let newErrorMessages = { ...dateErrorMessages };

    if (name === "mo_datedeb3" || name === "mo_datefin3") {
      const mo_datedeb3 =
        name === "mo_datedeb3" ? value : formState.mo_datedeb3;
      const mo_datefin3 =
        name === "mo_datefin3" ? value : formState.mo_datefin3;

      if (new Date(mo_datedeb3) > new Date(mo_datefin3)) {
        newErrorMessages.mo_datedeb3 =
          "La date de début ne peut pas être avant la première date proposé.";
        newErrorMessages.mo_datefin3 =
          "La date de fin ne peut pas être avant la date de début.";
      } else {
        newErrorMessages.mo_datedeb3 = "";
        newErrorMessages.mo_datefin3 = "";
      }

      // Validation contre les autres périodes de validité (ex: ope_datedeb1 et ope_datefin1)
      const { ope_datedeb1, ope_datefin1 } = operationsData;
      if (new Date(mo_datedeb3) < new Date(ope_datedeb1)) {
        newErrorMessages.mo_datedeb3 =
          "La date de début ne peut pas être avant la première date de validité.";
      }
    }

    setDateErrorMessages(newErrorMessages);

    // Mettre à jour l'état du formulaire seulement si les validations passent
    if (!newErrorMessages.mo_datedeb3 && !newErrorMessages.mo_datefin3) {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleInputChangeForId = async (e, id) => {
    const { value } = e.target;
    const param = operationsData.params.find(
      (p) => p.id_operation_param === id
    );
    if (param && parseInt(value) < param.opt_qte_min) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [id]: `La quantité minimale pour ${param.opt_libelle} est de ${param.opt_qte_min}`,
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
      setInputPlvValues((prevState) => ({
        ...prevState,
        [id]: value,
      }));
      try {
        await saveMapOption({
          mag_code: magasin.mag_code,
          id_operation: idOpe,
          id_operation_param: id,
          moo_qte: value,
        });
        const magOpeResponse = await saveMagOpe(formState);
        setBudgetData(magOpeResponse.CxMagOpe);
      } catch (error) {
        console.error("Error saving map option", error);
      }
    }
  };

  const handleBonialChoixChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      mo_bonial: value,
      mo_bonial_qte: value === "Non" ? "0" : prevState.mo_bonial_qte,
    }));
  };

  const handlePersonnalisationChoixChange = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      mo_courrier_personnalisation: value,
      mo_courrier_personnalisation_txt:
        value === "Non" ? "0" : prevState.mo_courrier_personnalisation_txt,
    }));
  };

  const handleDeleteMagOpe = async (data) => {
    const requestData = {
      mag_code: magasin.mag_code,
      id_operation: data,
    };
    try {
      await deleteMagOpe(requestData);
      navigate(PATH.CMNATIONALE + "/" + PATH.ENGACOM);
    } catch (error) {
      console.error("Failed to delete operation:", error);
    }
  };
  const handleSaveMagOpe = () => {
    navigate(PATH.CMNATIONALE + "/" + PATH.MES_ENGAGEMENTS);
  };

  return (
    <OperationContext.Provider
      value={{
        operationsData,
        magOpeData,
        isLoading,
        errorMessages,
        dateErrorMessages,
        inputPlvValues,
        budgetData,
        formState,
        pagiOptions,
        resultMagOpe,
        handleInputChange,
        handleInputChangeForId,
        handleBonialChoixChange,
        handlePersonnalisationChoixChange,
        handleDeleteMagOpe,
        handleSaveMagOpe,
      }}
    >
      {children}
    </OperationContext.Provider>
  );
};

export default OperationProvider;
